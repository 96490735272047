import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import './datatables.scss';
import {
  contractOps,
  dictAliases,
  ERC20txs,
  Polygontxs,
} from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';
import { PLATFORM } from '../../constants.js';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      startDate: new Date(),
      activeTab: '1',
      transactions: null,
      limit: false,
      aliases: {},
      loading: true,
      csvRows: [],
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  loadFA2txs = () => {
    this._asyncRequest = contractOps('token')
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let rows = [],
            csvRows = [];
          res.data.forEach((op) => {
            if (
              op.parameters &&
              op.is_success &&
              op.parameters.entrypoint !== 'transfer'
            ) {
              let params =
                op.parameters.value[op.parameters.entrypoint] ||
                op.parameters.value;
              rows.push({
                operationId: truncStringPortion(op.hash, 8, 6),
                operationType:
                  op.parameters.entrypoint === 'process_transfer'
                    ? 'transfer'
                    : op.parameters.entrypoint,
                date: op.time.replace('T', ' ').replace('Z', '') + ' GMT',
                parameters: (
                  <span className="badge font-size-9 text-left">
                    {['string', 'integer', 'boolean'].includes(
                      typeof params,
                    ) ? (
                      ['mint', 'burn'].includes(op.parameters.entrypoint) ? (
                        <span>
                          {parseFloat(params / this.state.decimals)}{' '}
                          <SVGLogo width="13" height="13" />
                        </span>
                      ) : (
                        params.toString()
                      )
                    ) : (
                      <>
                        {Object.keys(params).map(
                          (e, i) =>
                            params[e] && (
                              <div key={i} className="mt-1">
                                {e} :
                                <span className="ml-1 text-break">
                                  {[
                                    'amount',
                                    'balance',
                                    'value',
                                    'allowance',
                                  ].includes(e) ? (
                                    <span>
                                      {parseFloat(
                                        params[e] / this.state.decimals,
                                      )}{' '}
                                      <SVGLogo width="13" height="13" />
                                    </span>
                                  ) : this.state.aliases[
                                      params[e].toString()
                                    ] &&
                                    this.state.aliases[params[e].toString()]
                                      .name ? (
                                    this.state.aliases[params[e].toString()]
                                      .name
                                  ) : JSON.stringify(params[e]).length > 42 ? (
                                    truncStringPortion(
                                      JSON.stringify(params[e]),
                                      12,
                                      12,
                                    )
                                  ) : (
                                    JSON.stringify(params[e])
                                  )}
                                </span>
                              </div>
                            ),
                        )}
                      </>
                    )}
                  </span>
                ),
                caller:
                  this.state.aliases[op.sender] &&
                  this.state.aliases[op.sender].name
                    ? this.state.aliases[op.sender].name
                    : truncStringPortion(op.sender, 8, 6),
                more: (
                  <a
                    as="span"
                    className="badge badge-primary font-size-11 clickable"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${res.explorer
                      .replace('api.', '')
                      .replace('explorer/', '')}${op.hash}`}
                  >
                    View Details
                  </a>
                ),
              });

              csvRows.push({
                operationId: op.hash,
                operationType: op.parameters.entrypoint,
                date: op.time.replace('T', ' ').replace('Z', '') + ' GMT',
                parameters: ['string', 'integer', 'boolean'].includes(
                  typeof params,
                )
                  ? ['mint', 'burn'].includes(op.parameters.entrypoint)
                    ? parseFloat(params / this.state.decimals) + ' €'
                    : params.toString()
                  : Object.keys(params).map((e, i) => {
                      let nm =
                        ['amount', 'balance', 'value', 'alowance'].includes(
                          e,
                        ) && params[e]
                          ? parseFloat(params[e] / this.state.decimals) + ' €'
                          : params[e] &&
                            this.state.aliases[params[e].toString()] &&
                            this.state.aliases[params[e].toString()].name
                          ? this.state.aliases[params[e].toString()].name
                          : params[e] && params[e].toString();
                      return `${e}: ${nm}`;
                    }),
                caller:
                  this.state.aliases[op.sender] &&
                  this.state.aliases[op.sender].name
                    ? this.state.aliases[op.sender].name
                    : op.sender,
                more: `https://tzstats.com/${op.hash}`,
              });
            }
          });
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'Parameters',
                  field: 'parameters',
                  width: 160,
                },
                {
                  label: 'Caller',
                  field: 'caller',
                  sort: 'asc',
                  width: 104,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: rows,
            },
            loading: false,
            csvRows,
          });
        } else {
          console.log(res.error);
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'Parameters',
                  field: 'parameters',
                  width: 160,
                },
                {
                  label: 'Caller',
                  field: 'caller',
                  sort: 'asc',
                  width: 104,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: [],
            },
            loading: false,
            csvRows: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadERC20txs = () => {
    this._asyncRequest = ERC20txs()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let rows = [],
            csvRows = [];
          res.data.forEach((op) => {
            rows.push({
              operationId: truncStringPortion(op.txHash, 8, 6),
              operationType: op.txType,
              date: new Date(parseInt(op.timeStamp) * 1000).toISOString(),
              amount: op.amount,
              from:
                this.state.aliases[op.from] && this.state.aliases[op.from].name
                  ? this.state.aliases[op.from].name
                  : op.from,
              to:
                this.state.aliases[op.to] && this.state.aliases[op.to].name
                  ? this.state.aliases[op.to].name
                  : op.to,
              more: (
                <a
                  as="span"
                  className="badge badge-primary font-size-11 clickable"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    PLATFORM === 'staging'
                      ? `https://sepolia.etherscan.io/tx/${op.txHash}`
                      : this.props.activeToken === 'polygon'
                      ? `https://polygonscan.com/tx/${op.txHash}`
                      : `https://etherscan.io/tx/${op.txHash}`
                  }
                >
                  View Details
                </a>
              ),
            });

            csvRows.push({
              operationId: truncStringPortion(op.txHash, 8, 6),
              operationType: op.txType,
              date: op.timeStamp,
              amount: op.amount,
              from: op.from,
              to: op.to,
              more:
                PLATFORM === 'staging'
                  ? `https://sepolia.etherscan.io/tx/${op.txHash}`
                  : this.props.activeToken === 'polygon'
                  ? `https://polygonscan.com/tx/${op.txHash}`
                  : `https://etherscan.io/tx/${op.txHash}`,
            });
          });
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'EUROP amount',
                  field: 'amount',
                  width: 160,
                },
                {
                  label: 'From',
                  field: 'from',
                  width: 160,
                },
                {
                  label: 'To',
                  field: 'to',
                  width: 160,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: rows,
            },
            loading: false,
            csvRows,
          });
        } else {
          console.log(res.error);
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'EUROP amount',
                  field: 'amount',
                  width: 160,
                },
                {
                  label: 'From',
                  field: 'from',
                  width: 160,
                },
                {
                  label: 'To',
                  field: 'to',
                  width: 160,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: [],
            },
            loading: false,
            csvRows: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadPolygontxs = () => {
    this._asyncRequest = Polygontxs()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let rows = [],
            csvRows = [];
          res.data.forEach((op) => {
            rows.push({
              operationId: truncStringPortion(op.txHash, 8, 6),
              operationType: op.txType,
              date: new Date(parseInt(op.timeStamp) * 1000).toISOString(),
              amount: op.amount,
              from:
                this.state.aliases[op.from] && this.state.aliases[op.from].name
                  ? this.state.aliases[op.from].name
                  : op.from,
              to:
                this.state.aliases[op.to] && this.state.aliases[op.to].name
                  ? this.state.aliases[op.to].name
                  : op.to,
              more: (
                <a
                  as="span"
                  className="badge badge-primary font-size-11 clickable"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    PLATFORM === 'prod'
                      ? `https://polygonscan.com/tx/${op.txHash}`
                      : `https://sepolia.etherscan.io/tx/${op.txHash}`
                  }
                >
                  View Details
                </a>
              ),
            });

            csvRows.push({
              operationId: truncStringPortion(op.txHash, 8, 6),
              operationType: op.txType,
              date: op.timeStamp,
              amount: op.amount,
              from: op.from,
              to: op.to,
              more: `https://polygonscan.com/tx/${op.txHash}`,
            });
          });
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'EUROP amount',
                  field: 'amount',
                  width: 160,
                },
                {
                  label: 'From',
                  field: 'from',
                  width: 160,
                },
                {
                  label: 'To',
                  field: 'to',
                  width: 160,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: rows,
            },
            loading: false,
            csvRows,
          });
        } else {
          console.log(res.error);
          this.setState({
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'operationId',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation Type',
                  field: 'operationType',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'desc',
                  width: 136,
                },
                {
                  label: 'EUROP amount',
                  field: 'amount',
                  width: 160,
                },
                {
                  label: 'From',
                  field: 'from',
                  width: 160,
                },
                {
                  label: 'To',
                  field: 'to',
                  width: 160,
                },
                {
                  label: 'More',
                  field: 'more',
                  sort: 'asc',
                  width: 100,
                },
              ],
              rows: [],
            },
            loading: false,
            csvRows: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.activeToken === 'erc20') this.loadERC20txs();
    else if (this.props.activeToken === 'polygon') this.loadPolygontxs();
    else this.loadFA2txs();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">
                        Last EUROP {this.props.activeToken.toUpperCase()}{' '}
                        operations
                      </h4>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="p-3"
                      >
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.transactions}
                            className="mt-1"
                            hover
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { activeToken } = state.Token;
  return { activeToken };
};

export default connect(mapStatetoProps)(withTranslation()(Transactions));
