import * as React from 'react';

function SVGLogo(props) {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      width={props.width || '20'}
      height={props.height || '20'}
      {...props}
      viewBox="0 0 1250 1200"
    >
      <g>
        <path d="M886.8,590l-26.8,100h300v-193.92c0-58.59-47.49-106.08-106.08-106.08h-167.13l-26.8,100h160.71c21.7,0,39.29,17.59,39.29,39.29v60.71h-173.21ZM816.65,790c-31.29,0-56.65-28.64-56.65-68.59v-362.82c0-39.95,25.36-68.59,56.65-68.59h416.56l26.8-100h-442.53c-86.97,0-157.47,70.47-157.47,170.85v358.3c0,100.38,70.5,170.85,157.47,170.85h442.53l-26.8-100h-416.56Z" />
      </g>
    </svg>
  );
}

export default SVGLogo;
