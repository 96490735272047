import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

// Token switch
import Token from "./token/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Token
});

export default rootReducer;
