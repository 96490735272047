import React from "react";
import { Route, Redirect } from "react-router-dom";

import { check } from "../components/Can";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	user,
	action,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {

				if (isAuthProtected && (!user || !user.user || !user.user.id)) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}

				if (isAuthProtected && action && !check(user.user.role, action, props.match && props.match.params)) {
					return (
						<Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
					);
				}

				if (props.match && props.match.params.contract && props.match.params.op) {
					return (
						<Layout>
							<Component {...props} key={`financial-${props.match.params.contract}-${props.match.params.op}`} />
						</Layout>
					);
				}

				if (props.match && props.match.params.role && props.match.params.op) {
					return (
						<Layout>
							<Component {...props} key={`financial-${props.match.params.contract}-${props.match.params.op}`} />
						</Layout>
					);
				}

				if (props.match && props.match.params.role) {
					return (
						<Layout>
							<Component {...props} key={`erc20-proposals-${props.match.params.role}`} />
						</Layout>
					);
				}

				if (props.match && props.match.params.publicKeyHash) {
					return (
						<Layout>
							<Component {...props} key={`oca-${props.match.params.publicKeyHash}`} />
						</Layout>
					);
				}

				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

