import * as React from "react"

function TezosLogo(props) {
  return (
    <svg viewBox="0 0 1600 1600" width={props.width || "20"} height={props.height || "20"} {...props}>
    <path
      fill="currentColor"
      d="m 755.68,1593 q -170.51,0 -248.91,-82.14 a 253.6,253.6 0 0 1 -78.15,-177 117.39,117.39 0 0 1 13.69,-58.5 101.21,101.21 0 0 1 37.33,-37.36 130.22,130.22 0 0 1 116.24,0 99.55,99.55 0 0 1 37.12,37.36 115,115 0 0 1 14.18,58.5 111.73,111.73 0 0 1 -19.91,68.45 92.78,92.78 0 0 1 -47.31,34.62 129.18,129.18 0 0 0 74.67,46.55 370,370 0 0 0 101.8,14.68 226.91,226.91 0 0 0 128.19,-38.33 224,224 0 0 0 83.63,-113.25 492,492 0 0 0 27.38,-169.5 A 465.07,465.07 0 0 0 965.76,1000.85 217.54,217.54 0 0 0 879.39,891.33 229.68,229.68 0 0 0 754.96,855.74 236.75,236.75 0 0 0 647.18,892.33 L 567.26,932.4 V 892.33 L 926.43,410.5 H 428.62 v 500 a 178.9,178.9 0 0 0 27.38,102.3 94.34,94.34 0 0 0 83.63,40.07 139.85,139.85 0 0 0 82.63,-29.12 298.38,298.38 0 0 0 69.2,-71.19 24.86,24.86 0 0 1 9,-11.94 18.4,18.4 0 0 1 12,-4.48 41.55,41.55 0 0 1 23.4,9.95 49.82,49.82 0 0 1 12.69,33.85 197.86,197.86 0 0 1 -4.48,24.89 241,241 0 0 1 -85.38,106 211.78,211.78 0 0 1 -119.76,36.38 q -161.67,0 -224,-63.72 A 238.67,238.67 0 0 1 253.2,909.25 V 410.5 H 0 V 317.6 H 254.38 V 105.78 L 196.14,47.5 V 0 h 169 l 63.48,32.86 V 317.6 l 657.6,-2 65.47,65.71 -403.23,405.19 a 271,271 0 0 1 76.16,-18.42 330.1,330.1 0 0 1 147.38,42.07 302.7,302.7 0 0 1 126.95,113.29 399.78,399.78 0 0 1 57.25,136.65 575.65,575.65 0 0 1 13.69,117 489.39,489.39 0 0 1 -49.78,216.79 317.92,317.92 0 0 1 -149.35,149.35 483.27,483.27 0 0 1 -215.08,49.77 z"
    />
  </svg>
  )
}

export default TezosLogo
