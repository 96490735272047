import * as React from "react"

function EthereumLogo(props) {
  return (
    <svg viewBox="0 0 400 400" width={props.width || "20"} height={props.height || "20"} {...props}>
    <path fill="currentColor" d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"/>
    <path fill="currentColor" d="M127.962 287.959V0L0 212.32z"/>
    <path fill="currentColor" d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"/>
    <path fill="currentColor" d="M0 236.585l127.962 180.32v-104.72z"/>
    <path fill="currentColor" d="M127.961 154.159v133.799l127.96-75.637z"/>
    <path fill="currentColor" d="M127.96 154.159L0 212.32l127.96 75.637z"/>
  </svg>
  )
}

export default EthereumLogo