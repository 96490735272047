import rules from '../rbac-rules'

export const check = (role, action, data) => {
  if (!role) {
    return false
  }
  
  const permissions = rules[role.toLowerCase()]
  if (!permissions) {
    return false
  }

  const staticPermissions = permissions.static
  if (staticPermissions && staticPermissions.includes(action)) {
    return true
  }

  const dynamicPermissions = permissions.dynamic
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      return false
    }

    return permissionCondition(data)
  }

  return false
}


const Can = ({ role, perform, data, yes, no }) =>
  check(role, perform, data)
    ? yes()
    : no();


Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can