import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import { truncStringPortion } from '../../helpers/formatter.js';
import logo from '../../assets/images/logo.png';

class ERC20Storage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <div className="wcard-schuman">
            <Row>
              <Col xs="8">
                <div className="p-4 mb-4">
                  <h5 className="text-white">ERC20 Token</h5>
                </div>
              </Col>
              <Col xs="4" className="align-self-end"></Col>
            </Row>
          </div>
          <CardBody>
            <div className="text-center mb-4">
              <div className="mx-auto">
                <Avatar src={logo} size={82} />
              </div>
              <p className="font-16 text-muted mb-2">Contract ID</p>
              <h5 className="font-size-14">{this.props.storage.token}</h5>
              <p className="font-16 text-muted mb-3"></p>
              <hr />
              <h5>
                <Link to="#" className="text-dark">
                  Transfer status -
                  {this.props.storage.paused &&
                  this.props.storage.paused.toString() === 'true' ? (
                    <span className="text-danger font-16 ml-1">Paused</span>
                  ) : (
                    <span className="text-info font-16 ml-1">Active</span>
                  )}
                </Link>
              </h5>
            </div>
            <Row className="mb-1">
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span className="avatar-title rounded-circle bg-primary font-size-16">
                      <i className="mdi mdi-cog-outline text-white"></i>
                    </span>
                  </div>
                  <h5 className="font-size-12">Administrator</h5>
                  <p className="text-muted mb-0 font-size-10">
                    {truncStringPortion(this.props.storage.admin, 7, 6)}
                  </p>
                </div>
              </Col>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span className="avatar-title rounded-circle bg-info font-size-16">
                      <i className="mdi mdi-stamper text-white"></i>
                    </span>
                  </div>
                  <h5 className="font-size-12">Master Minter</h5>
                  <p className="text-muted mb-0 font-size-10">
                    {truncStringPortion(this.props.storage.master_minter, 7, 6)}
                  </p>
                </div>
              </Col>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span className="avatar-title rounded-circle bg-pink font-size-16">
                      <i className="mdi mdi-gavel text-white"></i>
                    </span>
                  </div>
                  <h5 className="font-size-12">Owner</h5>
                  <p className="text-muted mb-0 font-size-10">
                    {truncStringPortion(this.props.storage.owner, 7, 6)}
                  </p>
                </div>
              </Col>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span className="avatar-title rounded-circle bg-dark font-size-16">
                      <i className="mdi mdi-stamper text-white"></i>
                    </span>
                  </div>
                  <h5 className="font-size-12">Delegatted minters</h5>
                  {this.props.storage.minters &&
                    Object.keys(this.props.storage.minters).map((minter) => (
                      <p className="text-muted mb-0 font-size-10">
                        {truncStringPortion(minter, 7, 6)}
                      </p>
                    ))}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ERC20Storage;
